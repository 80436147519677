import { ArticleCard } from 'components/ArticleCard';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import Switch from 'react-switch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiMiniClock } from 'react-icons/hi2';
import { IoCalendarClearSharp } from 'react-icons/io5';
import Select from 'react-select';
import pdfIcon from 'assets/images/pdfIcon.png';
import EditorByJodit from 'components/Editor/EditorByJodit';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { useAppDispatch } from 'store/hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import { createReport, getReportByID, updateReport } from 'services/researchCenter.service';
import { GENERAL_SERVER_ERROR, SUCCESS } from 'constant';
import { AddReportParamsType, ReportResearchData, ReportSubmitType } from 'types/data.type';
import { getUrlUploadFile } from 'store/slices/announcement/announcementAPI';
import { ErrorMessage } from 'components';
import { getReportType, REPORT_TYPE_OPTIONS } from 'utils';

const statusOptions = [
  {
    label: 'Published',
    value: 'Published',
  },
  {
    label: 'Unpublished',
    value: 'Unpublished',
  },
  {
    label: 'Draft',
    value: 'Draft',
  },
];

enum TAB {
  VIETNAMESE,
  ENGLISH,
}

type LinkFileType = {
  file: string;
  file_url: string;
};

const AddReportResearchManagement = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    trigger,
    control,
    formState: { errors },
  } = useForm<ReportSubmitType>({ mode: 'onChange' });
  const fileReportRef = useRef<HTMLInputElement>(null);
  const fileReportENRef = useRef<HTMLInputElement>(null);
  const paramsURL = useParams();
  const paramsId = paramsURL?.id;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [status, setStatus] = useState(false);
  const [publishedDate, setPublishedDate] = useState(new Date());
  const [publishedHours, setPublishedHours] = useState(new Date());
  const [isReview, setIsReview] = useState(false);
  const [tab, setTab] = useState(TAB.VIETNAMESE);

  const [fileReportError, setFileReportError] = useState('');
  const [progressReportFile, setProgressReportFile] = useState(0);
  const [fileReport, setFileReport] = useState<File | null>(null);
  const [linkFileReport, setLinkFileReport] = useState<LinkFileType>();

  const [fileReportENError, setFileReportENError] = useState('');
  const [progressReportENFile, setProgressReportENFile] = useState(0);
  const [fileReportEN, setFileReportEN] = useState<File | null>(null);
  const [linkFileReportEN, setLinkFileReportEN] = useState<LinkFileType>();

  const [editorShortState, setEditorShortState] = useState<string>();
  const [editorENShortState, setEditorENShortState] = useState<string>();

  const [editorLongState, setEditorLongState] = useState<string>();
  const [editorENLongState, setEditorENLongState] = useState<string>();

  const [tags, setTags] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const [reportResearchData, setReportResearchData] = useState<ReportResearchData>();

  const selected = (url: string) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const crumbs = [
    {
      name: 'Trung tâm phân tích',
      url: '/report-research-management',
    },
    {
      name: paramsId ? 'Chỉnh sửa phân tích' : 'Tạo phân tích mới',
    },
  ];

  const handleUploadReportFile = async (file: any) => {
    const paramsGetUrlUpload = {
      file_type: 'AnalysisReport',
    };
    const resLinkStore = await getUrlUploadFile(paramsGetUrlUpload);
    const data = resLinkStore?.data;
    if (data?.status === SUCCESS) {
      const filePath = data?.data?.file_path;
      const fileUrl = data?.data?.url_image;
      let urlUpload = data?.data?.url;
      if (urlUpload) {
        const res = await fetch(urlUpload, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file?.type,
          },
        });
        if (res.status === 200) {
          return {
            file: filePath,
            file_url: fileUrl,
          };
        } else {
          toast.error('Có lỗi xảy ra trong quá trình Upload File!');
          return false;
        }
      } else {
        toast.error('Có lỗi xảy ra trong quá trình Upload File!');
        return false;
      }
    } else {
      toast.error('Có lỗi xảy ra trong quá trình Upload File!');
      return false;
    }
  };

  const submitAddHandler: SubmitHandler<ReportSubmitType> = async values => {
    setSubmitAdd(true);
    let params: AddReportParamsType = {
      ...values,
      is_post_now: status,
      files: [
        {
          file_url: linkFileReport?.file || '',
          display_name: fileReport?.name || reportResearchData?.files?.[0]?.display_name || '',
        },
      ],
      files_en: [
        {
          file_url: linkFileReportEN?.file || '',
          display_name: fileReportEN?.name || reportResearchData?.files_en?.[0]?.display_name || '',
        },
      ],
      short_content: editorShortState || '',
      full_content: editorLongState || '',
      short_content_en: editorENShortState || '',
      full_content_en: editorENLongState || '',
      tags: tags,
      type: values?.report_type?.value,
      status: values?.status ? values?.status?.value : 'Draft',
    };

    if (!status) {
      params = {
        ...params,
        published_date:
          moment(publishedHours)
            .year(publishedDate.getFullYear())
            .month(publishedDate.getMonth())
            .date(publishedDate.getDate())
            .add(7, 'hours')
            .toISOString() || '',
      };
    }
    if (!linkFileReport?.file) {
      delete params.files;
    }

    if (!linkFileReportEN?.file) {
      delete params.files_en;
    }

    if (paramsId) {
      params = {
        ...params,
        is_reviewed: isReview,
      };
      try {
        const res = await updateReport(params, paramsId);
        if (res?.status === SUCCESS) {
          toast.success('Chỉnh sửa báo cáo phân tích thành công');
        } else {
          toast.error(res?.error_message?.split('|')[1] || 'Có lỗi xảy ra vui lòng thử lại!');
        }
      } catch (error: any) {
        const errors = error?.response?.data;
        errors?.map((item: any) => {
          return toast.error(item?.error_message || GENERAL_SERVER_ERROR);
        });
      }
    } else {
      try {
        const res = await createReport(params);
        if (res?.status === SUCCESS) {
          toast.success('Tạo mới báo cáo phân tích thành công');
          navigate(`/report-research-management`);
        } else {
          toast.error(res?.error_message?.split('|')[1] || 'Có lỗi xảy ra vui lòng thử lại!');
        }
      } catch (error: any) {
        const errors = error?.response?.data;
        errors?.map((item: any) => {
          return toast.error(item?.error_message || GENERAL_SERVER_ERROR);
        });
      }
    }
    setSubmitAdd(false);
  };

  const increaseProgressReportFile = (url: any) => {
    let percent = 0;
    const interval = setInterval(async () => {
      if (percent < 90) {
        percent += 10;
      }
      if (percent >= 90 && url) {
        percent = 100;
        clearInterval(interval);
      }
      if (tab === TAB.VIETNAMESE) {
        setProgressReportFile(percent);
      } else {
        setProgressReportENFile(percent);
      }
    }, 100);
  };

  const loadFileReport = (file: any) => {
    const reader = new FileReader();

    reader.onprogress = async event => {
      if (event.lengthComputable) {
        if (file) {
          const urlData = await handleUploadReportFile(file);
          if (urlData) {
            tab === TAB.VIETNAMESE ? setLinkFileReport(urlData) : setLinkFileReportEN(urlData);
          }
          increaseProgressReportFile(urlData);
        }
      }
    };

    reader.onload = () => {
      // console.log('File đã được tải vào thành công!');
      if (tab === TAB.VIETNAMESE) {
        setFileReport(file);
      } else {
        setFileReportEN(file);
      }
    };

    reader.onerror = () => {
      if (tab === TAB.VIETNAMESE) {
        setFileReportError('Có lỗi xảy ra khi tải file');
        setProgressReportFile(0);
      } else {
        setFileReportENError('Có lỗi xảy ra khi tải file');
        setProgressReportENFile(0);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleUploadFile = (e: any, type?: string) => {
    const file = e.target.files[0];
    const currentSize = Math.floor(file.size / 1024 / 1024);

    switch (tab) {
      case TAB.VIETNAMESE:
        if (file.type !== 'application/pdf') {
          setFileReportError('Vui lòng chọn tệp PDF.');
        } else if (currentSize >= 10) {
          setFileReportError('Dung lượng file không được lớn hơn 10MB');
        } else {
          setFileReportError('');
          loadFileReport(file);
        }
        break;
      case TAB.ENGLISH:
        if (file.type !== 'application/pdf') {
          setFileReportENError('Vui lòng chọn tệp PDF.');
        } else if (currentSize >= 10) {
          setFileReportENError('Dung lượng file không được lớn hơn 10MB');
        } else {
          setFileReportENError('');
          loadFileReport(file);
        }
        break;
      default:
        break;
    }
  };

  const clearFileReport = () => {
    if (fileReportRef.current) {
      fileReportRef.current.value = '';
    }
  };

  const clearFileReportEN = () => {
    if (fileReportENRef.current) {
      fileReportENRef.current.value = '';
    }
  };

  const onEditorShortContentChange = (value: any) => {
    setEditorShortState(value);
    setValue('short_content', value, {
      shouldValidate: true,
    });
  };

  const onEditorShortContentChangeEN = (value: any) => {
    setEditorENShortState(value);
    setValue('short_content_en', value, {
      shouldValidate: true,
    });
  };

  const onEditorLongContentChange = (value: any) => {
    setEditorLongState(value);
    setValue('full_content', value, {
      shouldValidate: true,
    });
  };

  const onEditorLongContentChangeEN = (value: any) => {
    setEditorENLongState(value);
    setValue('full_content_en', value, {
      shouldValidate: true,
    });
  };

  const handleAddTag = (event: any) => {
    if (event.key !== 'Enter') return;

    const valueInput = event.target.value;

    if (!valueInput.trim()) return;

    const checkTags = tags?.some((item: any) => {
      return item === valueInput;
    });

    if (checkTags) {
      setError('tags', {
        type: 'custom',
        message: 'Tag đã tồn tại',
      });
      return;
    }

    setTags(prevState => {
      return [...prevState, valueInput];
    });

    event.target.value = '';
  };

  const handleRemoveTag = (tag: string) => {
    setTags(prevState => {
      return prevState.filter((item: string) => item !== tag);
    });
  };

  useEffect(() => {
    if (paramsId) {
      const getReportData = async () => {
        setLoading(true);
        try {
          const res = await getReportByID(paramsId || '');
          if (res?.status === SUCCESS) {
            const data = res?.data;
            const originalDate = new Date(data?.published_date);
            originalDate.setHours(originalDate.getHours() - 7);
            setPublishedDate(originalDate);
            setPublishedHours(originalDate);
            setValue(
              'report_type',
              {
                label: getReportType(data?.type) || '',
                value: data?.type,
              },
              {
                shouldValidate: true,
              }
            );
            setValue(
              'status',
              {
                label: data?.status || '',
                value: data?.status,
              },
              {
                shouldValidate: true,
              }
            );
            setIsReview(data?.is_reviewed);
            setStatus(data?.is_post_now);
            setValue('title', data?.title, {
              shouldValidate: true,
            });
            setValue('title_en', data?.title_en, {
              shouldValidate: true,
            });
            setValue('slug', data?.slug, {
              shouldValidate: true,
            });
            setTags(data?.tags);
            if (data?.files) {
              setLinkFileReport({
                file: data?.files[0]?.file,
                file_url: data?.files[0]?.file_url,
              });
            }
            if (data?.files_en) {
              setLinkFileReportEN({
                file: data?.files_en[0]?.file,
                file_url: data?.files_en[0]?.file_url,
              });
            }
            setEditorShortState(data?.short_content);
            setEditorENShortState(data?.short_content_en);
            setEditorLongState(data?.full_content);
            setEditorENLongState(data?.full_content_en);
            setReportResearchData(data);
          } else {
            toast.error(res?.error_message?.split('|')[1] || GENERAL_SERVER_ERROR);
          }
        } catch (error) {
          toast.error(GENERAL_SERVER_ERROR);
        }
        setLoading(false);
      };

      getReportData();
    }
  }, [paramsId]);

  const onError = (error: any) => {
    if (error?.title || error?.tags || error?.short_content || error?.full_content) {
      toast.error('Vui lòng điền đầy đủ thông tin tiếng việt.');
    } else if (
      error?.title_en ||
      error?.tags_en ||
      error?.short_content_en ||
      error?.full_content_en
    ) {
      toast.error('Vui lòng điền đầy đủ thông tin tiếng anh.');
    } else {
      toast.error('Vui lòng điền đầy đủ thông tin.');
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      ) : (
        <>
          <Breadcrumb crumbs={crumbs} selected={selected} />
          <div className="flex justify-between items-center">
            <TitlePage
              icon={() => <AiOutlineBook />}
              name={`${paramsId ? 'Chỉnh sửa phân tích' : 'Tạo phân tích mới'}`}
            />
            <button
              id="submitUpdate"
              type="submit"
              className="flex justify-center w-[106px] form-button"
              disabled={submitAdd}
              onClick={handleSubmit(submitAddHandler, onError)}
            >
              {submitAdd && <CircleSpin />}
              Lưu
            </button>
          </div>
          <div className="mt-[23px] relative">
            <form className="form" noValidate autoComplete="off">
              <div className="flex flex-col gap-2">
                <ArticleCard className="w-full !p-6">
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-[13px]">
                      <label htmlFor="category_type" className="font-bold">
                        Đăng ngay
                      </label>
                      <Switch
                        onChange={(value: any) => {
                          setStatus(value);
                        }}
                        checked={status}
                        className="react-switch"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={24}
                        width={44}
                        handleDiameter={18}
                        onColor={'#61CB2F'}
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </div>
                    <div className="lg:flex lg:gap-4">
                      <div className="form-group w-1/2">
                        <label htmlFor="category_order">Ngày đăng</label>
                        <div className="relative">
                          <DatePicker
                            selected={publishedDate}
                            onChange={(date: any) => {
                              setPublishedDate(date);
                            }}
                            className="form-control z-50"
                            placeholderText="Chọn Ngày đăng"
                            dateFormat="dd/MM/yyyy"
                            popperPlacement="bottom"
                            minDate={new Date()}
                            disabled={status}
                          />
                          <div className="absolute right-[14px] top-[9px]">
                            <IoCalendarClearSharp size={20} color={status ? '#898989' : ''} />
                          </div>
                        </div>
                        <ErrorMessage name="published_date" errors={errors} />
                      </div>
                      <div className="form-group w-1/2">
                        <label htmlFor="category_order">Giờ đăng</label>
                        <div className="relative">
                          <DatePicker
                            selected={publishedHours}
                            onChange={(date: any) => {
                              setPublishedHours(date);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            className="form-control z-90"
                            placeholderText="Chọn Giờ đăng"
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            popperPlacement="bottom"
                            disabled={status}
                          />
                          <div className="absolute right-[9px] top-[6.5px]">
                            <HiMiniClock size={25} color={status ? '#898989' : ''} />
                          </div>
                        </div>
                        <ErrorMessage name="published_date" errors={errors} />
                      </div>
                    </div>
                  </div>
                </ArticleCard>
                <ArticleCard className="w-full !p-6">
                  <div className="flex flex-col gap-2">
                    <div className="lg:flex lg:gap-4">
                      <div className="form-group w-1/2">
                        <label htmlFor="provider" className="required-after">
                          Nhà cung cấp
                        </label>
                        <input
                          id="provider"
                          type="text"
                          autoComplete="provider"
                          placeholder="Nhập câu hỏi"
                          className="form-control"
                          value="SBBS"
                          readOnly
                          {...register('provider', {
                            required: 'Vui lòng chọn nhà cung cấp',
                            setValueAs: (value: string) => value?.trim(),
                          })}
                        />
                      </div>
                      <div className="form-group w-1/2">
                        <label htmlFor="report_type" className="required-after">
                          Loại Báo cáo
                        </label>
                        <Controller
                          name="report_type"
                          rules={{
                            required: 'Vui lòng chọn loại báo cáo',
                          }}
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                id="report_type"
                                placeholder="Chọn loại báo cáo"
                                options={REPORT_TYPE_OPTIONS}
                                onChange={option => {
                                  if (option) {
                                    field.onChange(option);
                                    trigger('report_type');
                                  }
                                }}
                                classNamePrefix={'custom-select'}
                              />
                            );
                          }}
                        />
                        <ErrorMessage name="report_type" errors={errors} />
                      </div>
                    </div>
                    <div className="lg:flex lg:gap-4 select-group-style">
                      <div className="form-group w-1/2">
                        <label htmlFor="status" className="required-after">
                          Trạng thái
                        </label>
                        <Controller
                          name="status"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                id="status"
                                placeholder="Chọn trạng thái"
                                options={statusOptions}
                                onChange={option => {
                                  if (option) {
                                    field.onChange(option);
                                    setValue('status', option, {
                                      shouldValidate: true,
                                    });
                                  }
                                }}
                                //   value={getValues('status')}
                                defaultValue={{
                                  label: 'Draft',
                                  value: 'Draft',
                                }}
                                classNamePrefix={'custom-select'}
                              />
                            );
                          }}
                        />
                        <ErrorMessage name="status" errors={errors} />
                      </div>
                      <div className="form-group w-1/2">
                        {paramsId && (
                          <div className="checkbox-style h-full mt-3">
                            <input
                              type="checkbox"
                              id="isReview"
                              checked={isReview}
                              onChange={e => setIsReview(e.target.checked)}
                            />
                            <label htmlFor="allow_advance" className="font-semibold">
                              Đánh dấu đã review
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </ArticleCard>
                <div className="flex pt-1 pl-1">
                  <div
                    className={`px-3 py-2 cursor-pointer ${
                      tab === TAB.VIETNAMESE
                        ? 'text-black bg-white border border-gray-200 border-b-0 rounded-t-[4px]'
                        : 'text-grey-dark bg-primary-c3 border-b border-gray-200'
                    }`}
                    onClick={() => {
                      if (tab !== TAB.VIETNAMESE) {
                        setTab(TAB.VIETNAMESE);
                      }
                    }}
                  >
                    Tiếng việt
                  </div>
                  <div
                    className={`px-3 py-2 cursor-pointer ${
                      tab === TAB.ENGLISH
                        ? 'text-black bg-white border border-gray-200 border-b-0 rounded-t-[4px]'
                        : 'text-grey-dark bg-primary-c3 border-b border-gray-200'
                    }`}
                    onClick={() => {
                      if (tab !== TAB.ENGLISH) {
                        setTab(TAB.ENGLISH);
                      }
                    }}
                  >
                    English
                  </div>
                  <div className="flex-1 border-b border-gray-200"></div>
                </div>
                <div className="relative h-[170vh]">
                  <div
                    className={`absolute inset-0 ${
                      tab === TAB.VIETNAMESE ? 'z-10' : 'z-0 opacity-0'
                    }`}
                  >
                    <ArticleCard className="w-full !p-6">
                      <div className="form-group">
                        <label htmlFor="title" className="required-after">
                          Tiêu đề
                        </label>
                        <input
                          id="title"
                          type="text"
                          autoComplete="title"
                          placeholder="Nhập tiêu đề"
                          className="form-control"
                          {...register('title', {
                            required: 'Vui lòng nhập tiêu đề',
                          })}
                        />
                        <ErrorMessage name="title" errors={errors} />
                      </div>
                      <div className="lg:flex lg:gap-4 mt-[18px]">
                        <div className="form-group w-1/2">
                          <label htmlFor="slug" className="required-after">
                            Slug
                          </label>
                          <input
                            id="slug"
                            type="text"
                            autoComplete="slug"
                            placeholder="Nhập slug"
                            className="form-control"
                            {...register('slug', {
                              required: 'Vui lòng nhập slug',
                              setValueAs: (value: string) => value?.trim(),
                            })}
                          />
                          <ErrorMessage name="slug" errors={errors} />
                        </div>
                        <div className="form-group w-1/2">
                          <label htmlFor="tags" className="required-after">
                            Tags
                          </label>
                          <div className="px-3 rounded-md border border-gray-Bright flex items-center gap-4 flex-wrap h-[38px]">
                            {tags?.map((item: string) => (
                              <div
                                className="flex px-2 gap-2 items-center rounded bg-grey-light h-[26px]"
                                key={item}
                              >
                                <p className="text-sm text-grey-dark">{item}</p>
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleRemoveTag(item);
                                  }}
                                >
                                  <IoMdCloseCircleOutline size={16} />
                                </div>
                              </div>
                            ))}
                            <input
                              id="tags"
                              type="text"
                              autoComplete="tags"
                              placeholder="Nhập tags"
                              className="form-control !w-[150px] !border-none max-h-[26px]"
                              onKeyDown={handleAddTag}
                              {...register('tags', {
                                required: tags?.length > 0 ? false : 'Vui lòng nhập tags',
                                setValueAs: (value: string) => value?.trim(),
                              })}
                            />
                          </div>
                          <ErrorMessage name="tags" errors={errors} />
                        </div>
                      </div>
                    </ArticleCard>
                    <ArticleCard className="w-full !p-6">
                      <div className="form-group !my-0">
                        <label htmlFor="tag">Upload File Báo cáo</label>
                        <div className="mt-2 flex flex-col gap-3 items-center py-1 rounded-lg border border-dashed border-blue-800 relative">
                          <div className="flex flex-col items-center gap-2">
                            <p className="text-sm font-semibold leading-5">
                              Drag your file(s) or{' '}
                              <span className="font-bold text-blue-800">browse</span>
                            </p>
                            <p className="text-xs text-gray-300 -tracking-[0.12px]">
                              Max 10 MB files are allowed
                            </p>
                          </div>
                          <input
                            type="file"
                            ref={fileReportRef}
                            accept="application/pdf"
                            className="absolute inset-0 opacity-0"
                            onChange={e => {
                              handleUploadFile(e);
                            }}
                          />
                        </div>
                        {fileReportError && (
                          <span className="text-red-main text-xs">{fileReportError}</span>
                        )}
                      </div>
                      {(fileReport || linkFileReport?.file) && (
                        <div
                          className={`p-4 pb-[3px] flex flex-col gap-2 border border-gray-200 rounded-xl mt-4 ${
                            !fileReport && reportResearchData?.files?.[0] ? '!pb-4' : ''
                          }`}
                        >
                          <div className="flex justify-between">
                            <a
                              href={linkFileReport?.file_url}
                              className="flex gap-2"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={pdfIcon} alt="PDF Icon" className="w-9 h-9" />
                              <div className="flex flex-col gap-[2px] text-xs justify-center">
                                <p className="font-semibold leading-[18px]">
                                  {fileReport?.name || reportResearchData?.files?.[0]?.display_name}
                                </p>
                                <p className="font-normal leading-4 text-gray-300">
                                  {fileReport ? `${Math.floor(fileReport.size / 1024)}kb` : ''}
                                </p>
                              </div>
                            </a>
                            <div
                              onClick={() => {
                                setFileReport(null);
                                setProgressReportFile(0);
                                clearFileReport();
                                setLinkFileReport({} as LinkFileType);
                              }}
                              className="cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4ZM16 14.59L14.59 16L12 13.41L9.41 16L8 14.59L10.59 12L8 9.41L9.41 8L12 10.59L14.59 8L16 9.41L13.41 12L16 14.59Z"
                                  fill="#858585"
                                />
                                <path
                                  d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                                  fill="#858585"
                                />
                              </svg>
                            </div>
                          </div>
                          {fileReport && (
                            <div className="flex gap-2 items-center">
                              <div className="w-full bg-gray-50 rounded-full h-2">
                                <div
                                  className="bg-blue-800 h-2 rounded-full animation-progress"
                                  style={{ width: `${progressReportFile}%` }}
                                ></div>
                              </div>
                              <span className="text-xs text-gray-300 leading-[16px]">
                                {progressReportFile}%
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </ArticleCard>
                    <ArticleCard className="w-full !p-6">
                      <h3 className="text-2xl font-semibold">Short content</h3>
                      <div className="form-group !mt-4">
                        <label htmlFor="short_content" className="required-before">
                          Mô tả
                        </label>
                        <div className="w-full min-h-[300px]">
                          <EditorByJodit
                            editorValue={editorShortState}
                            saveEditorStateValues={onEditorShortContentChange}
                          />
                          <input
                            id="short_content"
                            type="hidden"
                            autoComplete="short_content"
                            className="form-control"
                            {...register('short_content', {
                              required: 'Vui lòng nhập mô tả',
                            })}
                          />
                          <ErrorMessage name="short_content" errors={errors} />
                        </div>
                      </div>
                    </ArticleCard>
                    <ArticleCard className="w-full !p-6">
                      <h3 className="text-2xl font-semibold">Long content</h3>
                      <div className="form-group !mt-4">
                        <label htmlFor="full_content" className="required-before">
                          Mô tả
                        </label>
                        <div className="w-full min-h-[300px]">
                          <EditorByJodit
                            editorValue={editorLongState}
                            saveEditorStateValues={onEditorLongContentChange}
                          />
                          <input
                            id="full_content"
                            type="hidden"
                            autoComplete="full_content"
                            className="form-control"
                            {...register('full_content', {
                              required: 'Vui lòng nhập mô tả',
                            })}
                          />
                          <ErrorMessage name="full_content" errors={errors} />
                        </div>
                      </div>
                    </ArticleCard>
                  </div>
                  <div
                    className={`absolute inset-0 ${tab === TAB.ENGLISH ? 'z-10' : 'z-0 opacity-0'}`}
                  >
                    <ArticleCard className="w-full !p-6">
                      <div className="form-group">
                        <label htmlFor="title_en" className="required-after">
                          Tiêu đề (EN)
                        </label>
                        <input
                          id="title_en"
                          type="text"
                          placeholder="Nhập tiêu đề (EN)"
                          className="form-control"
                          {...register('title_en', {
                            required: 'Vui lòng nhập tiêu đề (EN)',
                            setValueAs: (value: string) => value?.trim(),
                          })}
                        />
                        <ErrorMessage name="title_en" errors={errors} />
                      </div>
                    </ArticleCard>
                    <ArticleCard className="w-full !p-6">
                      <div className="form-group !my-0">
                        <label htmlFor="file_en">Upload File Báo cáo (EN)</label>
                        <div className="mt-2 flex flex-col gap-3 items-center py-1 rounded-lg border border-dashed border-blue-800 relative">
                          <div className="flex flex-col items-center gap-2">
                            <p className="text-sm font-semibold leading-5">
                              Drag your file(s) or{' '}
                              <span className="font-bold text-blue-800">browse</span>
                            </p>
                            <p className="text-xs text-gray-300 -tracking-[0.12px]">
                              Max 10 MB files are allowed
                            </p>
                          </div>
                          <input
                            type="file"
                            ref={fileReportENRef}
                            accept="application/pdf"
                            className="absolute inset-0 opacity-0"
                            onChange={e => {
                              handleUploadFile(e);
                            }}
                          />
                        </div>
                        {fileReportENError && (
                          <span className="text-red-main text-xs">{fileReportENError}</span>
                        )}
                      </div>
                      {(fileReportEN || linkFileReportEN?.file) && (
                        <div
                          className={`p-4 pb-[3px] flex flex-col gap-2 border border-gray-200 rounded-xl mt-4 
                        ${!fileReportEN && reportResearchData?.files_en?.[0] ? '!pb-4' : ''}
                        `}
                        >
                          <div className="flex justify-between">
                            <a
                              href={linkFileReportEN?.file_url}
                              className="flex gap-2"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={pdfIcon} alt="PDF Icon" className="w-9 h-9" />
                              <div className="flex flex-col gap-[2px] text-xs justify-center">
                                <p className="font-semibold leading-[18px]">
                                  {fileReportEN?.name ||
                                    reportResearchData?.files_en?.[0]?.display_name}
                                </p>
                                <p className="font-normal leading-4 text-gray-300">
                                  {fileReportEN ? `${Math.floor(fileReportEN.size / 1024)}kb` : ''}
                                </p>
                              </div>
                            </a>
                            <div
                              onClick={() => {
                                setFileReportEN(null);
                                setProgressReportENFile(0);
                                clearFileReportEN();
                                setLinkFileReportEN({} as LinkFileType);
                              }}
                              className="cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4ZM16 14.59L14.59 16L12 13.41L9.41 16L8 14.59L10.59 12L8 9.41L9.41 8L12 10.59L14.59 8L16 9.41L13.41 12L16 14.59Z"
                                  fill="#858585"
                                />
                                <path
                                  d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                                  fill="#858585"
                                />
                              </svg>
                            </div>
                          </div>
                          {fileReportEN && (
                            <div className="flex gap-2 items-center">
                              <div className="w-full bg-gray-50 rounded-full h-2">
                                <div
                                  className="bg-blue-800 h-2 rounded-full animation-progress"
                                  style={{ width: `${progressReportENFile}%` }}
                                ></div>
                              </div>
                              <span className="text-xs text-gray-300 leading-[16px]">
                                {progressReportENFile}%
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </ArticleCard>
                    <ArticleCard className="w-full !p-6">
                      <h3 className="text-2xl font-semibold">Short content</h3>
                      <div className="form-group !mt-4">
                        <label htmlFor="short_content_en" className="required-before">
                          Mô tả (EN)
                        </label>
                        <div className="w-full min-h-[300px]">
                          <EditorByJodit
                            editorValue={editorENShortState}
                            saveEditorStateValues={onEditorShortContentChangeEN}
                          />
                          <input
                            id="short_content_en"
                            type="hidden"
                            autoComplete="short_content_en"
                            className="form-control"
                            {...register('short_content_en', {
                              required: 'Vui lòng nhập mô tả',
                            })}
                          />
                          <ErrorMessage name="short_content_en" errors={errors} />
                        </div>
                      </div>
                    </ArticleCard>
                    <ArticleCard className="w-full !p-6">
                      <h3 className="text-2xl font-semibold">Long content</h3>
                      <div className="form-group !mt-4">
                        <label htmlFor="full_content_en" className="required-before">
                          Mô tả (EN)
                        </label>
                        <div className="w-full min-h-[300px]">
                          <EditorByJodit
                            editorValue={editorENLongState}
                            saveEditorStateValues={onEditorLongContentChangeEN}
                          />
                          <input
                            id="full_content_en"
                            type="hidden"
                            autoComplete="full_content_en"
                            className="form-control"
                            {...register('full_content_en', {
                              required: 'Vui lòng nhập mô tả',
                            })}
                          />
                          <ErrorMessage name="full_content_en" errors={errors} />
                        </div>
                      </div>
                    </ArticleCard>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default AddReportResearchManagement;
