import Breadcrumb from 'components/Breadcrumb';
import { PermissionsGate } from 'components/PermissionsGate';
import { PaginationOwn } from 'components/Shared';
import TitlePage from 'components/TitlePage';
import { CircleSpin, ItemsPerPage } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import SearchButton from 'components/UiComponents/SearchButton/SearchButton';
import { GENERAL_SERVER_ERROR, PERMISSIONS_KEY, SUCCESS } from 'constant';
import { PERMISSIONS_ACTIONS } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { FaUserCog } from 'react-icons/fa';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteUserByID, getListUsers } from 'services/researchCenter.service';
import { ResponseApiList } from 'types';
import { UserReportParamsType, UserResearchData } from 'types/data.type';
import { getBgStatus, getColorTextStatus } from 'utils';

const crumbs = [
  {
    name: 'Quản lý khách hàng',
    url: '/user-research-management',
  },
];

const UserManagement = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [itemPerPage, setItemPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);
  const [userItem, setUserItem] = useState<UserResearchData>();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [users, setUsers] = useState<ResponseApiList<UserResearchData>>();

  const page = searchParams.get('page') || 1;

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const onSearch = (reset?: boolean) => {
    navigate(`/user-research-management`);
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const handlePageSizeChange = (value: number) => {
    navigate(`/user-research-management`);
    setItemPerPage(value);
  };

  const getListUser = async (params: UserReportParamsType) => {
    setLoading(true);
    try {
      const res = await getListUsers(params);
      if (res) {
        setUsers(res);
      }
    } catch (error) {
      toast.error(GENERAL_SERVER_ERROR);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Number(page)) {
      setCurrentPage(Number(page));
      const params: UserReportParamsType = {
        page_index: Number(page),
        page_size: itemPerPage,
      };

      if (keyword) {
        params.keyword = keyword;
      }

      getListUser(params);
    }
  }, [reloadPage, page, itemPerPage]);

  const onPageChange = (event: number) => {
    navigate(`/user-research-management?page=${event}`);
  };

  const handleDeleteUser = async () => {
    setSubmitDelete(true);
    try {
      const res = await deleteUserByID(userItem?.research_center_user_id || '');
      if (res?.status === SUCCESS) {
        toast.success('Xóa khách hàng thành công.');
      } else {
        toast.error(res?.error_message?.split('|')[1] || 'Xóa khách hàng thất bại.');
      }
    } catch (error) {
      toast.error('Xóa khách hàng thất bại.');
    }
    setSubmitDelete(false);
    setConfirmDeleteModal(false);
    setReloadPage(!reloadPage);
  };
  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex justify-between items-center">
        <TitlePage icon={() => <FaUserCog />} name="Quản lý khách hàng" />
        <PermissionsGate
          name={PERMISSIONS_KEY.ManageResearchCenter}
          action={PERMISSIONS_ACTIONS.CREATE}
        >
          <Link to={'/user-research-management/add'}>
            <span className="flex justify-center w-full md:w-52 form-button">
              Tạo khách hàng mới
            </span>
          </Link>
        </PermissionsGate>
      </div>
      <div className="flex mt-8">
        <div className="flex w-1/2 space-x-2 items-end">
          <SearchButton
            onSearch={onSearch}
            keyword={keyword}
            setKeyword={setKeyword}
            _handleKeyDown={_handleKeyDown}
          />
        </div>
        <div className="ml-auto flex items-end">
          <ItemsPerPage
            choice={itemPerPage}
            setChoice={(val: number) => {
              handlePageSizeChange(val);
            }}
          />
        </div>
      </div>
      <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
        <table className="table w-full rounded-lg">
          <thead className="bg-gray-f2 border-b-2 border-gray-200">
            <tr>
              <th style={{ width: '60px' }}>STT</th>
              <th>Họ và Tên</th>
              <th>Ngày sinh</th>
              <th>Email</th>
              <th>Số điện thoại</th>
              <th>Công ty</th>
              <th>Ngày tạo</th>
              <th>Đăng nhập</th>
              <th>Hành động</th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {users?.items?.map((item: UserResearchData, index: number) => (
                <tr key={item?.research_center_user_id}>
                  <td>{(currentPage - 1) * itemPerPage + index + 1}</td>
                  <td className="font-semibold max-w-[300px]">{item?.full_name}</td>
                  <td>{moment(item?.dob).format('DD/MM/YYYY')}</td>
                  <td>{item?.email}</td>
                  <td>{item?.phone}</td>
                  <td>{item?.company}</td>
                  <td>{moment(item?.created_date).format('DD/MM/YYYY')}</td>
                  <td>
                    <div className={`px-2 py-[5px] rounded w-fit ${getBgStatus(item?.status)}`}>
                      <span className={`${getColorTextStatus(item?.status)} `}>{item?.status}</span>
                    </div>
                  </td>
                  <td>
                    <div className="flex gap-2">
                      <PermissionsGate
                        name={PERMISSIONS_KEY.ManageResearchCenter}
                        action={PERMISSIONS_ACTIONS.UPDATE}
                      >
                        <div className="items-center text-base table-action-btn">
                          <Link to={`/user-research-management/${item?.research_center_user_id}`}>
                            <BiEdit className="text-0BA5EC text-lg" />
                          </Link>
                        </div>
                      </PermissionsGate>
                      <PermissionsGate
                        name={PERMISSIONS_KEY.ManageResearchCenter}
                        action={PERMISSIONS_ACTIONS.DELETE}
                      >
                        <div className="flex gap-3 items-center text-base table-action-btn">
                          <div
                            onClick={() => {
                              setUserItem(item);
                              setConfirmDeleteModal(true);
                            }}
                          >
                            <BiTrash className="text-red-main text-lg" />
                          </div>
                        </div>
                      </PermissionsGate>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {users?.items?.length === 0 && !loading && (
        <p className="text-center text-sm mt-3">No user found</p>
      )}
      {(users?.total_results || 0) > itemPerPage && !loading && (
        <div className="my-6 flex text-right">
          <PaginationOwn
            totalItems={users?.total_results || 0}
            itemPerPage={itemPerPage}
            pageChange={onPageChange}
            pageCurrent={currentPage}
          />
        </div>
      )}
      {loading && (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      )}
      <Modal
        show={confirmDeleteModal}
        title={`Xác Nhận Xóa Khách Hàng ${userItem?.full_name}`}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button-seconday"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="submit"
            className="flex justify-center w-full md:w-52 form-button"
            disabled={submitDelete}
            onClick={handleDeleteUser}
          >
            {submitDelete && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
    </>
  );
};

export default UserManagement;
